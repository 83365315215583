@import "theme";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

.company-logo {
    height: 3rem;
}

@media (min-width: 350px) {
    .company-logo {
        height: 4rem;
    }
}

@include media-breakpoint-up(md) {
    .dlcredit-container a {
        width: auto !important;
    }
}

.row.aboutus .preamble {
    align-items: flex-end;
}

.row.aboutus p {
    font-size: 1rem;
}

.employee-centering-container {
    justify-content: center;
}

@include media-breakpoint-up(lg) {
    .static-container,
    .static-container.tinted {
        background-position-y: -200px;
    }

    .row.aboutus .preamble,
    .row.aboutus .content {
        align-items: center;
        justify-content: center;
    }

    .row.aboutus p {
        font-size: 1.15rem;
    }

    .balanced-header {
        margin-top: 10rem;
    }

    .employee-centering-container {
        justify-content: flex-start;
    }
}
