/* Navigation */
#primary-navigation,
#primary-navigation img,
#primary-navigation a {
    transition: 0.4s;
}

#primary-navigation a.nav-link {
    color: black;
}

#primary-navigation .navbar-brand {
    padding: 1.2rem;
}

#primary-navigation .driver-login a {
    padding: 1rem;
}
