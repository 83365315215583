.static-container {
    background-image: url("../img/rbs-truck-side.prod.jpg");
    height: 650px;
    background-size: cover;

    &.slim {
        height: 60vh;
    }
}

.parallax-container {
    background-image: url("../img/rbs-truck-front.prod.jpeg");

    height: 70vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-img {
    height: 150px;

    img {
        height: 100%;
        object-fit: none;
    }

    &.compat-object-fit {
        background-size: auto !important;
        background-repeat: no-repeat;

        img {
            opacity: 0;
        }
    }
}

.services-img {
    img {
        object-fit: cover;
        height: 250px;
    }

    &.compat-object-fit {
        background-repeat: no-repeat;

        img {
            opacity: 0;
        }
    }
}

img.contain {
    object-fit: contain;
}

/* MODERN BROWSER COMPAT */
div.image-container {
    img.back-image {
        width: 100%;
        height: 100%;
        object-position: 50% 50%;
        object-fit: cover;
    }

    &.up-35 {
        img.back-image {
            object-position: 50% 85%;
        }
    }

    &.up-25 {
        img.back-image {
            object-position: 50% 75%;
        }
    }

    &.up-15 {
        img.back-image {
            object-position: 50% 65%;
        }
    }

    &.down-10 {
        img.back-image {
            object-position: 50% 40%;
        }
    }

    &.down-15 {
        img.back-image {
            object-position: 50% 35%;
        }
    }

    &.down-25 {
        img.back-image {
            object-position: 50% 25%;
        }
    }

    &.down-35 {
        img.back-image {
            object-position: 50% 15%;
        }
    }
}

/* LEGACY BROWSER COMPAT */
div.image-container.compat-object-fit {
    background-size: cover;
    background-position: 50% 50%;

    img.back-image {
        opacity: 0;
    }

    &.up-35 {
        background-position: 50% 85%;
    }

    &.up-25 {
        background-position: 50% 75%;
    }

    &.up-15 {
        background-position: 50% 65%;
    }

    &.down-10 {
        background-position: 50% 40%;
    }

    &.down-15 {
        background-position: 50% 35%;
    }

    &.down-25 {
        background-position: 50% 25%;
    }

    &.down-35 {
        background-position: 50% 15%;
    }
}
