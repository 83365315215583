@import "theme";

/* General Style */

body {
    font-family: Poppins;
    font-weight: 300;
    font-size: 1.05rem;
}

.flex-grow {
    flex-grow: 1;
}

/* Page specific style */

.first-row {
    margin-top: 105px;
}

.headline-col {
    background-color: rgba(41, 43, 44, 0.5);
}

.row.banner.banner-height {
    height: 350px;
}

.leader-text-container {
    height: 25vh;
}

.nav-item.active {
    font-weight: bold;
}

.content-block-lg {
    min-height: 400px;
}

.text-md {
    font-size: 1.5rem;
}

.weight-400 {
    font-weight: 400;
}

.weight-500 {
    font-weight: 500 !important;
}

.btn.dlcredit .icon {
    left: -35px;
    top: 4px;
}

.form-group.required label:after {
    content: "*";
}
